import { useState, useEffect, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { UserPermissionFeatures, UserPermissionRoles } from '~/api/types';
import { HookUserRolesAndPermissions } from './types';

import { selectCurrentUser } from '~/reducers/currentUserSlice';
import {
    setUserGroupsForCurrentUser,
    selectUserGroupsForCurrentUser
} from '~/reducers/userGroupsForCurrentUserSlice';

import constants from '~/utils/constants';
import utils from '~/utils/general-utils';
import { getUserGroupsAssignedToUser, validatePermissions } from './utils';

export const useUserRolesAndPermissions = (): HookUserRolesAndPermissions => {
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);

    const currentUser = useSelector(selectCurrentUser);
    const userGroupsForCurrentUser = useSelector(
        selectUserGroupsForCurrentUser
    );

    const { userId = '', userName = '' } = useMemo(() => {
        if (!currentUser) return {};

        const { id, firstname, lastname, username } = currentUser;
        const resolvedUserName = utils.getUserName(
            firstname,
            lastname,
            username
        );

        return {
            userId: id,
            userName: resolvedUserName
        };
    }, [currentUser]);

    const { refetch: userGroupsFetch } = useQuery(
        [constants.reactQueryKeys.USER_GROUPS, userId],
        async () => {
            const response = await getUserGroupsAssignedToUser(userId);

            if (!response.length) {
                setIsReady(false);
                throw new Error('No results');
            }

            setIsReady(true);
            return response;
        },
        {
            enabled: false,
            retry: 3,
            onSuccess: (data) => {
                dispatch(setUserGroupsForCurrentUser(data));
            }
        }
    );

    useEffect(() => {
        if (userId) userGroupsFetch();
    }, [userId, userGroupsFetch]);

    const isEditorRole = useCallback(
        (feature: UserPermissionFeatures, overrideRole = false): boolean => {
            const params = {
                userGroups: userGroupsForCurrentUser,
                role: UserPermissionRoles.EDITOR,
                feature,
                overrideRole
            };
            return validatePermissions(params);
        },
        [userGroupsForCurrentUser]
    );

    const isViewerRole = useCallback(
        (feature: UserPermissionFeatures, overrideRole = false): boolean => {
            const params = {
                userGroups: userGroupsForCurrentUser,
                role: UserPermissionRoles.VIEWER,
                feature,
                overrideRole
            };
            return validatePermissions(params);
        },
        [userGroupsForCurrentUser]
    );

    const isNoAccessRole = useCallback(
        (feature: UserPermissionFeatures, overrideRole = false): boolean => {
            const params = {
                userGroups: userGroupsForCurrentUser,
                role: UserPermissionRoles.NO_ACCESS,
                feature,
                overrideRole
            };
            return validatePermissions(params);
        },
        [userGroupsForCurrentUser]
    );

    return {
        isReady,
        userId,
        userName,
        isEditorRole,
        isViewerRole,
        isNoAccessRole
    };
};
