import userGroupsAPI from '~/api/user-groups';
import { ApiUserGroup } from '~/api/types';
import { ValidatePermissionsParams } from './types';

export const getUserGroupsAssignedToUser = async (
    userId: string
): Promise<ApiUserGroup[]> => {
    const {
        data: {
            data: { successes }
        }
    } = await userGroupsAPI.getUserGroupsAssignedToUser(userId);

    return successes;
};

export const validatePermissions = (
    params: ValidatePermissionsParams
): boolean => {
    const { userGroups, feature, role, overrideRole } = params;

    return userGroups.some((userGroup: ApiUserGroup) => {
        return userGroup.client_access.some((curClient) => {
            return curClient.permission.some(
                (permission) =>
                    permission.feature === feature &&
                    (permission.role === role || overrideRole)
            );
        });
    });
};
